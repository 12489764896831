import "./App.css";
import { useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Homepage from "./pages/Homepage/Homepage";
import Navbar from "./components/header/WebNavbar";
import MobileNavbar from "./components/header/MobileNavbar";
import Footer from "./components/footer/Footer";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactForm from "./pages/ContactForm";

function App() {
  const targetRef = useRef(null);

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language from i18n instance

  // Set root element's direction based on language
  document.documentElement.dir = currentLanguage === "ar" ? "rtl" : "ltr";

  // Function to set language preference in local storage
  const setLanguagePreference = (lng) => {
    localStorage.setItem("language", lng);
  };

  // Function to get language preference from local storage
  const getLanguagePreference = () => {
    return localStorage.getItem("language");
  };

  useEffect(() => {
    // Get language preference from local storage
    const savedLanguage = getLanguagePreference();

    if (savedLanguage) {
      // If language preference exists, set it as the current language
      i18n.changeLanguage(savedLanguage);
    }
  }, []); // Run only once on component mount

  return (
    <div className="App">
      <Navbar
        scrollToTarget={scrollToTarget}
        setLanguagePreference={setLanguagePreference}
      />
      <MobileNavbar setLanguagePreference={setLanguagePreference} />
      <Routes>
        <Route path="/" element={<Homepage targetRef={targetRef} />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactForm />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
