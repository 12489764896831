import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="w-full flex flex-col bg-[#00001C] px-5 lg:px-[60px] pt-[45px]">
      <div className="flex flex-col lg:flex-row items-center justify-between gap-5 lg:gap-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="430"
          height="68"
          viewBox="155 0 200 68"
          fill="none"
          className="w-[25%] hidden lg:block"
        >
          <script xmlns="" />
          <path
            d="M119.956 52.237C113.055 52.237 106.288 50.093 101.397 45.604L105.886 38.636C109.772 42.388 115.4 44.197 120.358 44.197C125.45 44.197 128.532 42.12 128.532 38.703C128.532 34.482 123.775 33.142 118.348 31.534C110.844 29.39 102.469 26.576 102.469 17.263C102.469 8.352 110.107 3.796 118.348 3.796C125.048 3.796 131.011 5.873 135.433 9.625L130.81 16.526C127.527 13.444 122.502 11.903 118.415 11.903C113.859 11.903 111.045 13.913 111.045 17.129C111.045 20.948 115.668 22.221 121.162 23.829C129.068 25.973 137.443 28.988 137.443 38.234C137.443 47.279 129.604 52.237 119.956 52.237ZM163.68 52.304C151.821 52.304 144.518 45.47 144.518 33.812V4.6H153.362V33.276C153.362 40.11 157.181 44.197 163.68 44.197C170.179 44.197 173.931 40.11 173.931 33.276V4.6H182.775V33.812C182.775 45.738 175.204 52.304 163.68 52.304ZM193.411 51.5V4.6H205.94L217.263 33.745H217.866L229.189 4.6H241.718V51.5H233.142V15.32H232.673L220.948 45.135H214.181L202.456 15.32H201.987V51.5H193.411ZM252.95 51.5V4.6H281.894V12.372H261.727V24.097H280.554V31.467H261.727V43.728H282.564V51.5H252.95ZM291.816 51.5V4.6H309.772C320.157 4.6 326.388 10.094 326.388 19.005V19.675C326.388 25.705 323.239 30.328 318.08 32.472L329.604 51.5H319.219L309.571 34.415H300.593V51.5H291.816ZM300.392 27.514H308.7C314.663 27.514 317.611 24.633 317.611 20.01V19.34C317.611 14.784 314.596 12.104 308.767 12.104H300.392V27.514Z"
            fill="#FFF"
          />
          <rect x="253" y="4.5" width="29" height="8" fill="#27AE60" />
          <script xmlns="" />
        </svg>
        <svg
          width="430"
          height="68"
          viewBox="0 0 430 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-[75%] lg:hidden"
        >
          <path
            d="M119.956 52.237C113.055 52.237 106.288 50.093 101.397 45.604L105.886 38.636C109.772 42.388 115.4 44.197 120.358 44.197C125.45 44.197 128.532 42.12 128.532 38.703C128.532 34.482 123.775 33.142 118.348 31.534C110.844 29.39 102.469 26.576 102.469 17.263C102.469 8.352 110.107 3.796 118.348 3.796C125.048 3.796 131.011 5.873 135.433 9.625L130.81 16.526C127.527 13.444 122.502 11.903 118.415 11.903C113.859 11.903 111.045 13.913 111.045 17.129C111.045 20.948 115.668 22.221 121.162 23.829C129.068 25.973 137.443 28.988 137.443 38.234C137.443 47.279 129.604 52.237 119.956 52.237ZM163.68 52.304C151.821 52.304 144.518 45.47 144.518 33.812V4.6H153.362V33.276C153.362 40.11 157.181 44.197 163.68 44.197C170.179 44.197 173.931 40.11 173.931 33.276V4.6H182.775V33.812C182.775 45.738 175.204 52.304 163.68 52.304ZM193.411 51.5V4.6H205.94L217.263 33.745H217.866L229.189 4.6H241.718V51.5H233.142V15.32H232.673L220.948 45.135H214.181L202.456 15.32H201.987V51.5H193.411ZM252.95 51.5V4.6H281.894V12.372H261.727V24.097H280.554V31.467H261.727V43.728H282.564V51.5H252.95ZM291.816 51.5V4.6H309.772C320.157 4.6 326.388 10.094 326.388 19.005V19.675C326.388 25.705 323.239 30.328 318.08 32.472L329.604 51.5H319.219L309.571 34.415H300.593V51.5H291.816ZM300.392 27.514H308.7C314.663 27.514 317.611 24.633 317.611 20.01V19.34C317.611 14.784 314.596 12.104 308.767 12.104H300.392V27.514Z"
            fill="#FFF"
          />
          <rect x="253" y="4.5" width="29" height="8" fill="#27AE60" />
        </svg>

        <ul className="flex flex-row justify-between lg:justify-start items-center gap-7">
          <Link
            to="/terms-conditions"
            className="text-[14px] lg:text-[1em] text-[#fffbf9] font-normal"
            style={{
              fontFamily: `${
                currentLanguage === "en"
                  ? "Montserrat, Sans-serif"
                  : "Tajawal, Sans-serif"
              }`,
            }}
          >
            {t("terms&Conditions")}
          </Link>
          <Link
            to="/privacy-policy"
            className="text-[14px] lg:text-[1em] text-[#fffbf9] font-normal"
            style={{
              fontFamily: `${
                currentLanguage === "en"
                  ? "Montserrat, Sans-serif"
                  : "Tajawal, Sans-serif"
              }`,
            }}
          >
            {t("privacyPolicy")}
          </Link>
          <Link
            to="/contact-us"
            className="text-[14px] lg:text-[1em] text-[#fffbf9] font-normal"
            style={{
              fontFamily: `${
                currentLanguage === "en"
                  ? "Montserrat, Sans-serif"
                  : "Tajawal, Sans-serif"
              }`,
            }}
          >
            {t("contactUs")}
          </Link>
        </ul>
        <div className="flex lg:hidden flex-row justify-start items-center gap-7 p-2.5">
          {/* LinkedIn */}
          <a href="https://www.linkedin.com/company/102285099/" target="blank">
            <svg
              className="w-[30px] h-[30px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="#fffbf9"
                d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
              />
            </svg>
          </a>
          {/* Instagram */}
          <a href="https://www.instagram.com/iraqproperty/" target="blank">
            <svg
              className="w-[30px] h-[30px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="#fffbf9"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>
        </div>
      </div>
      {/* divider */}
      <div className="w-full bg-[#fffbf9] h-[1px] my-[30px]"></div>
      {/* **** */}
      <div className="flex flex-row items-center justify-between">
        <p
          className={`w-full lg:w-[40%] ${
            currentLanguage === "en"
              ? "text-[12px] lg:text-[10px]"
              : "text-[12px]"
          } leading-[21px] text-[#fffbf9] font-normal p-2.5 mb-[5px] text-center lg:text-start`}
          style={{
            fontFamily: `${
              currentLanguage === "en"
                ? "Montserrat, Sans-serif"
                : "Tajawal, Sans-serif"
            }`,
          }}
        >
          {t("footerText")}
        </p>

        <div className="lg:flex flex-row justify-start items-center gap-7 p-2.5 hidden">
          {/* LinkedIn */}
          <a href="https://www.linkedin.com/company/102285099/" target="blank">
            <svg
              className="w-[30px] h-[30px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="#fffbf9"
                d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
              />
            </svg>
          </a>
          {/* Instagram */}
          <a href="https://www.instagram.com/iraqproperty/" target="blank">
            <svg
              className="w-[30px] h-[30px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="#fffbf9"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>
        </div>
      </div>
      {/* divider */}
      <div className="w-full bg-[#fffbf9] h-[1px] my-[30px]"></div>
      {/* **** */}
      <p
        className={`${
          currentLanguage === "en" ? "text-[12px]" : "text-[16px]"
        } leading-[21px] mb-[0.5em] text-[#fffbf9] font-normal pb-5`}
        style={{
          fontFamily: `${
            currentLanguage === "en"
              ? "Montserrat, Sans-serif"
              : "Tajawal, Sans-serif"
          }`,
        }}
      >
        {t("copyright")}
      </p>
    </div>
  );
}

export default Footer;
