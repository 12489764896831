import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import extensiveListingImg from "../../assets/extensive-listing.png";
import searchFiltersImg from "../../assets/search-filters.png";
import propertyDetailsImg from "../../assets/property-details.png";
import savedSearchesImg from "../../assets/img-4.png";
import propertyViewingImg from "../../assets/3d-viewing.png";

function SectionTwo() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col px-5 lg:px-16 lg:w-[80%]">
      <div
        className="flex flex-wrap items-center mx-auto h-full"
        data-aos="fade-up"
        data-aos-duration="900"
        data-aos-once="true"
      >
        <p
          className="text-[30px] lg:text-[48px] font-bold leading-[30px] lg:leading-[1.3em] tracking-tighter text-[#00001C]"
          style={{ fontFamily: "Tajawal, Sans-serif" }}
        >
          {t("sectionTwoTitle")}
        </p>
      </div>
      <div
        className="flex flex-col lg:flex-row justify-between items-center pt-[40px] lg:pt-[60px] lg:pb-20 rounded-xl mt-10 lg:mt-0 mb-5 px-5 lg:px-10"
        style={{
          boxShadow:
            "0px 14px 55px 0px rgba(208.00000000000003, 201.99999999999997, 238.99999999999997, 0.3)",
        }}
      >
        <div
          id="text-box"
          className="lg:pr-8 text-center lg:text-start lg:mt-14 lg:max-w-[50%]"
          data-aos="fade-right"
          data-aos-duration="900"
          data-aos-once="true"
        >
          <h3
            className="text-[26px] lg:text-[40px] mb-[0.325em] font-semibold leading-[1.1em] tracking-[-0.5px] text-[#00001C]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemOneTitle")}
          </h3>
          <p
            className="text-[16px] lg:text-[20px] mb-[0.5em] font-normal leading-[18px] lg:leading-[1.1em] tracking-[-0.5px] text-[#454545]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemOneText")}
          </p>
        </div>
        <img
          loading="lazy"
          decoding="async"
          src={extensiveListingImg}
          alt=""
          className="w-[35%] lg:w-[15%] my-5 lg:my-0 lg:mr-20"
          data-aos="fade-left"
          data-aos-duration="900"
          data-aos-once="true"
        />
      </div>
      <div
        className="flex flex-col lg:flex-row-reverse justify-between items-center pt-[40px] lg:pt-[60px] lg:pb-20 rounded-xl mt-10 lg:mt-0 mb-5 px-5 lg:px-10"
        style={{
          boxShadow:
            "0px 14px 55px 0px rgba(208.00000000000003, 201.99999999999997, 238.99999999999997, 0.3)",
        }}
      >
        <div
          id="text-box"
          className="lg:pr-8 text-center lg:text-start lg:mt-14 lg:max-w-[50%]"
          data-aos="fade-right"
          data-aos-duration="900"
          data-aos-once="true"
        >
          <h3
            className="text-[26px] lg:text-[40px] mb-[0.325em] font-semibold leading-[1.1em] tracking-[-0.5px] text-[#00001C]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemTwoTitle")}
          </h3>
          <p
            className="text-[16px] lg:text-[20px] mb-[0.5em] font-normal leading-[18px] lg:leading-[1.1em] tracking-[-0.5px] text-[#454545]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemTwoText")}
          </p>
        </div>
        <img
          loading="lazy"
          decoding="async"
          src={searchFiltersImg}
          alt=""
          className="w-[35%] lg:w-[15%] my-5 lg:my-0 lg:ml-20"
          data-aos="fade-left"
          data-aos-duration="900"
          data-aos-once="true"
        />
      </div>
      <div
        className="flex flex-col lg:flex-row justify-between items-center pt-[40px] lg:pt-[60px] lg:pb-20 rounded-xl mt-10 lg:mt-0 mb-5 px-5 lg:px-10"
        style={{
          boxShadow:
            "0px 14px 55px 0px rgba(208.00000000000003, 201.99999999999997, 238.99999999999997, 0.3)",
        }}
      >
        <div
          id="text-box"
          className="lg:pr-8 text-center lg:text-start lg:mt-14 lg:max-w-[50%]"
          data-aos="fade-right"
          data-aos-duration="900"
          data-aos-once="true"
        >
          <h3
            className="text-[26px] lg:text-[40px] mb-[0.325em] font-semibold leading-[1.1em] tracking-[-0.5px] text-[#00001C]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemThreeTitle")}
          </h3>
          <p
            className="text-[16px] lg:text-[20px] mb-[0.5em] font-normal leading-[18px] lg:leading-[1.1em] tracking-[-0.5px] text-[#454545]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemThreeText")}
          </p>
        </div>
        <img
          loading="lazy"
          decoding="async"
          src={savedSearchesImg}
          alt=""
          className="w-[35%] lg:w-[15%] my-5 lg:my-0 lg:mr-20"
          data-aos="fade-left"
          data-aos-duration="900"
          data-aos-once="true"
        />
      </div>
      <div
        className="flex flex-col lg:flex-row-reverse justify-between items-center pt-[40px] lg:pt-[60px] lg:pb-20 rounded-xl mt-10 lg:mt-0 mb-5 px-5 lg:px-10"
        style={{
          boxShadow:
            "0px 14px 55px 0px rgba(208.00000000000003, 201.99999999999997, 238.99999999999997, 0.3)",
        }}
      >
        <div
          id="text-box"
          className="lg:pr-8 text-center lg:text-start lg:mt-14 lg:max-w-[50%]"
          data-aos="fade-right"
          data-aos-duration="900"
          data-aos-once="true"
        >
          <h3
            className="text-[26px] lg:text-[40px] mb-[0.325em] font-semibold leading-[1.1em] tracking-[-0.5px] text-[#00001C]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemFourTitle")}
          </h3>
          <p
            className="text-[16px] lg:text-[20px] mb-[0.5em] font-normal leading-[18px] lg:leading-[1.1em] tracking-[-0.5px] text-[#454545]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemFourText")}
          </p>
        </div>
        <img
          loading="lazy"
          decoding="async"
          src={propertyDetailsImg}
          alt=""
          className="w-[35%] lg:w-[15%] my-5 lg:my-0 lg:ml-20"
          data-aos="fade-left"
          data-aos-duration="900"
          data-aos-once="true"
        />
      </div>
      <div
        className="flex flex-col lg:flex-row justify-between items-center pt-[40px] lg:pt-[60px] lg:pb-20 rounded-xl mt-10 lg:mt-0 mb-5 px-5 lg:px-10"
        style={{
          boxShadow:
            "0px 14px 55px 0px rgba(208.00000000000003, 201.99999999999997, 238.99999999999997, 0.3)",
        }}
      >
        <div
          id="text-box"
          className="lg:pr-8 text-center lg:text-start lg:mt-14 lg:max-w-[50%]"
          data-aos="fade-right"
          data-aos-duration="900"
          data-aos-once="true"
        >
          <h3
            className="text-[26px] lg:text-[40px] mb-[0.325em] font-semibold leading-[1.1em] tracking-[-0.5px] text-[#00001C]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemFiveTitle")}
          </h3>
          <p
            className="text-[16px] lg:text-[20px] mb-[0.5em] font-normal leading-[18px] lg:leading-[1.1em] tracking-[-0.5px] text-[#454545]"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {t("sectionTwoItemFiveText")}
          </p>
        </div>
        <img
          loading="lazy"
          decoding="async"
          src={propertyViewingImg}
          alt=""
          className="w-[35%] lg:w-[15%] my-5 lg:my-0 lg:mr-20"
          data-aos="fade-left"
          data-aos-duration="900"
          data-aos-once="true"
        />
      </div>
    </div>
  );
}

export default SectionTwo;
