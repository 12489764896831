import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import ScrollIndicator from "../components/ScrollIndicator";

const ContactForm = () => {
  const { t } = useTranslation();

  const currentLanguage = i18n.language;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await emailjs.send(
        "service_sg18fmm",
        "template_43elrr3",
        {
          first_name: firstName,
          last_name: lastName,
          user_email: email,
          message: message,
        },
        "X1s64r6GMeF1rA_vn"
      );

      if (result.status === 200) {
        setStateMessage("Message sent successfully!");
      } else {
        setStateMessage("Something went wrong, please try again later");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setStateMessage("Something went wrong, please try again later");
    }

    setIsSubmitting(false);

    setTimeout(() => {
      setStateMessage(null);
    }, 5000); // hide message after 5 seconds

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <div className="flex justify-center flex-col w-full gap-0 lg:gap-10 py-[50px]">
      <ScrollIndicator />
      <div id="text-box" className="text-center mx-auto mb-10">
        <h1
          className="text-[60px]  leading-[1.3em] text-[#00001c] font-bold tracking-[-0.05em] py-5"
          style={{
            fontFamily: `${
              currentLanguage === "en"
                ? "Poppins, Sans-serif"
                : "Tajawal, Sans-serif"
            }`,
          }}
        >
          {t("contactUs")}
        </h1>
      </div>
      <form onSubmit={sendEmail} className="w-[65%] mx-auto">
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 text-start"
              htmlFor="first_name"
            >
              {t("firstNameLabel")}
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              value={firstName}
              onChange={handleFirstNameChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder={`${t("firstNamePlaceholder")}`}
              required
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 text-start"
              htmlFor="last_name"
            >
              {t("lastNameLabel")}
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={lastName}
              onChange={handleLastNameChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder={`${t("lastNamePlaceholder")}`}
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 text-start"
            htmlFor="user_email"
          >
            {t("emailLabel")}
          </label>
          <input
            type="email"
            name="user_email"
            id="user_email"
            value={email}
            onChange={handleEmailChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder={`${t("emailPlaceholder")}`}
            required
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 text-start"
            htmlFor="message"
          >
            {t("messageLabel")}
          </label>
          <textarea
            name="message"
            id="message"
            rows="5"
            value={message}
            onChange={handleMessageChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder={`${t("messagePlaceholder")}`}
            required
          ></textarea>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : `${t("sendButton")}`}
          </button>
          {stateMessage && (
            <p className="text-sm text-gray-600">{stateMessage}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
