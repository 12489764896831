import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import Accordion from "../Accordion";

function SectionFive() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="flex flex-col px-0 lg:px-16 lg:w-[80%] gap-10"
      data-aos="fade-up"
      data-aos-duration="900"
      data-aos-once="true"
    >
      <div className="flex flex-col flex-wrap items-center mx-auto h-full">
        <p
          className="text-[36px] lg:text-[48px] font-bold leading-[1.3em] tracking-tighter text-[#000024]"
          style={{ fontFamily: "Tajawal, Sans-serif" }}
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-once="true"
        >
          {t("sectionFiveTitle")}
        </p>
        <p
          className="text-[26px] font-medium leading-[1.25em] tracking-tighter text-[#000024] my-[0.35em]"
          style={{ fontFamily: "Tajawal, Sans-serif" }}
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-once="true"
          data-aos-delay="200"
        >
          {t("sectionFiveSubtitle")}
        </p>
      </div>
      <div
        className="flex flex-col gap-[20px] mb-10"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-once="true"
        data-aos-delay="400"
      >
        <Accordion
          title={`${t("questionOneTitle")}`}
          content={[
            `${t("questionOneLineOne")}`,
            `${t("questionOneLineTwo")}`,
            `${t("questionOneLineThree")}`,
            `${t("questionOneLineFour")}`,
          ]}
        />
        <Accordion
          title={`${t("questionTwoTitle")}`}
          content={[
            `${t("questionTwoLineOne")}`,
            `${t("questionTwoLineTwo")}`,
            `${t("questionTwoLineThree")}`,
            `${t("questionTwoLineFour")}`,
            `${t("questionTwoLineFive")}`,
            `${t("questionTwoLineSix")}`,
          ]}
        />
        <Accordion
          title={`${t("questionThreeTitle")}`}
          content={[
            `${t("questionThreeLineOne")}`,
            `${t("questionThreeLineTwo")}`,
            `${t("questionThreeLineThree")}`,
            `${t("questionThreeLineFour")}`,
            `${t("questionThreeLineFive")}`,
            `${t("questionThreeLineSix")}`,
            `${t("questionThreeLineSeven")}`,
            `${t("questionThreeLineEight")}`,
          ]}
        />
        <Accordion
          title={`${t("questionFourTitle")}`}
          content={[
            `${t("questionFourLineOne")}`,
            `${t("questionFourLineTwo")}`,
            `${t("questionFourLineThree")}`,
            `${t("questionFourLineFour")}`,
          ]}
        />
        <Accordion
          title={`${t("questionFiveTitle")}`}
          content={[
            `${t("questionFiveLineOne")}`,
            `${t("questionFiveLineTwo")}`,
            `${t("questionFiveLineThree")}`,
            `${t("questionFiveLineFour")}`,
          ]}
        />
        <Accordion
          title={`${t("questionSixTitle")}`}
          content={[
            `${t("questionSixLineOne")}`,
            `${t("questionSixLineTwo")}`,
            `${t("questionSixLineThree")}`,
            `${t("questionSixLineFour")}`,
            `${t("questionSixLineFive")}`,
            `${t("questionSixLineSix")}`,
          ]}
        />
        <Accordion
          title={`${t("questionSevenTitle")}`}
          content={[
            `${t("questionSevenLineOne")}`,
            `${t("questionSevenLineTwo")}`,
          ]}
        />
        <Accordion
          title={`${t("questionEightTitle")}`}
          content={[
            `${t("questionEightLineOne")}`,
            `${t("questionEightLineTwo")}`,
            `${t("questionEightLineThree")}`,
            `${t("questionEightLineFour")}`,
          ]}
        />
      </div>
    </div>
  );
}

export default SectionFive;
