import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import AOS from "aos";
import "aos/dist/aos.css";

function SectionFour() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  const currentLanguage = i18n.language;

  const gradientStyle = {
    backgroundImage: " linear-gradient(0deg, #00F3B3 0%, #0091FF 100%)",
    background: "-webkit-linear-gradient(0deg, #00F3B3, #0091FF)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    fontFamily: `${
      currentLanguage === "en" ? "Poppins, Sans-serif" : "Tajawal, Sans-serif"
    }`,
  };

  return (
    <div
      id="download-app-section"
      className="flex flex-col justify-center items-center w-full mb-10 lg:mb-0 lg:h-[100vh] mx-auto gap-10"
      data-aos="fade-up"
      data-aos-duration="900"
      data-aos-once="true"
    >
      <div className="lg:flex flex-col justify-center items-center hidden">
        <p
          className={`${
            currentLanguage === "en" ? "text-[68px]" : "text-[92px] "
          } capitalize font-semibold leading-[44px] tracking-[1.2px] text-[#000024] mt-[68px] mb-[34px]`}
          style={{
            fontFamily: `${
              currentLanguage === "en"
                ? "Poppins, Sans-serif"
                : "Tajawal, Sans-serif"
            }`,
          }}
        >
          {t("downloadTheApp")}
        </p>
        <p
          className={`${
            currentLanguage === "en"
              ? "text-[58px] mb-[0.15em]"
              : "text-[92px] py-[0.30em]"
          } capitalize font-semibold leading-[44px] tracking-[1.2px] text-[#000024]`}
          style={gradientStyle}
        >
          {t("AvailableNow")}
        </p>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-5 lg:gap-7">
        <button
          className="flex flex-row items-center justify-between bg-[#000024] w-[260px] rounded-[100px] py-[0.5rem] px-[2rem]"
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-once="true"
        >
          <div className="flex flex-col text-start items-start p-2.5">
            <p
              className="text-[12px] font-normal leading-[22px] text-[#FFFBF9]"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-once="true"
              style={{
                fontFamily: `${
                  currentLanguage === "en"
                    ? "Montserrat, Sans-serif"
                    : "Tajawal, Sans-serif"
                }`,
              }}
            >
              {t("appleTextOne")}
            </p>
            <p
              className={`${
                currentLanguage === "en" ? "text-[18px]" : "text-[22px]"
              } font-normal leading-[22px] tracking-[-0.1px] text-[#FFFBF9]`}
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-once="true"
              style={{
                fontFamily: `${
                  currentLanguage === "en"
                    ? "Montserrat, Sans-serif"
                    : "Tajawal, Sans-serif"
                }`,
              }}
            >
              {t("appleTextTwo")}
            </p>
          </div>
          <div className="p-[10px]">
            <svg
              className="w-[27px] h-[36px]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="#fffbf9"
                d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
              />
            </svg>
          </div>
        </button>
        <button
          className="flex flex-row items-center justify-between bg-[#000024] w-[260px] rounded-[100px] py-[0.5rem] px-[2rem]"
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-once="true"
        >
          <div className="flex flex-col text-start items-start p-2.5">
            <p
              className="text-[12px] font-normal leading-[22px] text-[#FFFBF9]"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-once="true"
              style={{
                fontFamily: `${
                  currentLanguage === "en"
                    ? "Montserrat, Sans-serif"
                    : "Tajawal, Sans-serif"
                }`,
              }}
            >
              {t("googleTextOne")}
            </p>
            <p
              className="text-[18px] font-normal leading-[22px] tracking-[-0.8px] text-[#FFFBF9]"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-once="true"
              style={{ fontFamily: "Montserrat, Sans-serif" }}
            >
              Google Play
            </p>
          </div>
          <div className="p-[10px]">
            <svg
              className="w-[36px] h-[36px]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#fffbf9"
                d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

export default SectionFour;
